<template>
  <div>
    <vx-card>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <h3 class="mt-4 mb-4">
          Ingresar {{ $tc("message.warehouse", 1, user.countryCode) }}
        </h3>
      </vs-col>
      <vs-divider></vs-divider>
      <vs-row>
        <vs-col class="sm:w-full">
          <div class="w-full px-2 mt-2">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full"
              icon="fa-tag"
              label="Numero o identificador"
              color="primary"
              v-model="unitNumber"
              v-validate="'required'"
              name="Identificador_Bodega"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-full p-1">
          <div class="w-full sm:w-full mt-2">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full px-2"
              icon="fa-building"
              label="Número de torre"
              v-model="towerNumber"
              name="towerNumber"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-full p-1">
          <div class="w-full sm:w-full mt-2">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full px-2"
              label="Nivel"
              color="primary"
              min="0"
              v-model="level"
              v-validate="'required'"
              name="Nivel"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full">
          <div class="w-full px-2 mt-2">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full"
              icon="fa-money-bill"
              type="number"
              label="Precio"
              color="primary"
              v-model.number="price"
              v-validate="'required'"
              name="Precio_Parqueo"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full">
          <div class="w-full mt-2 px-2">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full"
              icon="fa-vector-square"
              type="number"
              label="M2"
              color="primary"
              v-model.number="squareMeters"
              v-validate="'required'"
              name="Metros_Cuadreados_Bodega"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full">
          <div class="w-full mt-2 px-2">
            <span>Seleccione Estado</span>
            <v-select v-model="status" :options="propertiesStatus"></v-select>
          </div>
        </vs-col>
      </vs-row>
      <br />
      <vs-row>
        <vs-col class="sm:w-full"></vs-col>
        <vs-col class="sm:w-full">
          <vs-button
            color="success"
            icon-pack="feather"
            icon="icon-save"
            type="filled"
            ref="loadableButton"
            id="button-with-loading"
            class="vs-con-loading__container w-full"
            :disabled="!fieldsReady"
            @click="storeWarehouse()"
            >Guardar
            {{ $tc("message.warehouse", 1, user.countryCode) }}</vs-button
          >
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import vSelect from "vue-select";
import api from "../services/property.api";

export default {
  name: "FormularioBodega",
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      unitNumber: "",
      price: 0,
      status: "",
      squareMeters: 0,
      towerNumber: "",
      level: "",
      propertiesStatus: [],
    };
  },
  async mounted() {
    const propertiesStatus = await api.getPropertyStatus();

    this.propertiesStatus = propertiesStatus.filter(
      (status) => status.id === "AVAILABLE" || status.id === "BLOCKED"
    );
    this.propertiesStatus = this.propertiesStatus.map((status) => ({
      label: status.name,
      value: status.id,
    }));
  },
  computed: {
    ...mapState("auth", ["user"]),
    fieldsReady() {
      if (
        !this.unitNumber ||
        !this.price ||
        !this.squareMeters ||
        !this.level ||
        !this.status
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    async storeWarehouse() {
      const projectId = this.$store.getters["project/currentProject"];
      try {
        await api.createExtraProperty(projectId, {
          type: "WAREHOUSE",
          status: this.status.value || "BLOCKED",
          unitNumber: this.unitNumber,
          level: this.level || "1",
          tower: this.towerNumber,
          price: this.price,
          totalArea: this.squareMeters,
        });

        this.status = "";
        this.unitNumber = "";
        this.level = "";
        this.tower = "";
        this.price = 0;
        this.totalArea = 0;

        this.$vs.notify({
          time: 6000,
          title: `${this.unitNumber} creado! 🏢🚀`,
          text: `Fue creado correctamente.`,
          color: "success",
        });

        this.unitNumber = "";
        this.price = 0;
        this.level = "";
        this.squareMeters = 0;
        this.towerNumber = "";

        this.$emit("closePopUp");
      } catch (e) {
        this.$vs.notify({
          title: `No ha sido posible crear la propiedad`,
          text: `No ha sido posible crear la propiedad`,
          color: "danger",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
