<template>
  <ListWarehouses />
</template>

<script>
import { mapState } from "vuex";
import ListWarehouses from "../components/ListWarehouses";

import { api } from "../../projects/services";

export default {
  components: {
    ListWarehouses,
  },
  data() {
    return {
      newPopUpActive: false,
      project: null
    };
  },
  async mounted() {
    const projectId = this.$store.state.project.currentProjectId;
    this.project = await api.get(projectId);
    this.$store.state.project.breadcrumbs = [
      { pageName: 'Edición de proyectos', route: '/proyectos' },
      {
        pageName: `${this.capitalizeFirstLetter} en ${this.project.name}`,
        route: `/bodegas/${this.$route.params.projectId}`
      }
    ];
  },
  computed: {
    ...mapState("auth", ["user"]),
    capitalizeFirstLetter() {
      let str = this.$tc('message.warehouse',2,this.user.countryCode);
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  },
  methods: {
    closeEditPopUp() {
      this.newPopUpActive = false;
    },

  }
};
</script>

<style lang="scss" scoped></style>
