<!-- ya no se usa -->
<template>
  <div>
    <vs-row>
      <router-link :to="{ name: 'Importador de Bodegas' }">
        <vs-button
          class="mt-4"
          color="primary"
          type="filled"
          icon-pack="fas"
          icon="fa-file-excel"
          >Importador de
          {{ $tc("message.warehouse", 2, user.countryCode) }}</vs-button
        >
      </router-link>
      <router-link :to="{ name: 'Importador de Bodegas con asignacion' }">
        <vs-button
          color="warning"
          text-color="#000"
          type="filled"
          icon-pack="fas"
          icon="fa-wave-square"
          class="m-4"
          >Asignador a propiedad</vs-button
        >
      </router-link>
    </vs-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("auth", ["user"])
  }
};
</script>
