<template>
  <div>
    <vx-card>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <h3 class="mt-4 mb-4">
          Actualización de {{ $tc("message.warehouse", 1, user.countryCode) }}
        </h3>
      </vs-col>
      <vs-divider></vs-divider>
      <vs-row>
        <vs-col class="sm:w-full">
          <div class="w-full px-2 mt-2">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full"
              icon="fa-tag"
              label="Numero o identificador"
              color="primary"
              v-model="unitNumber"
              v-validate="'required'"
              name="Identificador_Bodega"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-full p-1">
          <div class="w-full sm:w-full mt-2">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full px-2"
              icon="fa-building"
              label="Número de torre"
              v-model="towerNumber"
              name="towerNumber"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-full p-1">
          <div class="w-full sm:w-full mt-2">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full px-2"
              label="Nivel"
              color="primary"
              min="0"
              v-model="level"
              name="Nivel"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full">
          <div class="w-full px-2 mt-2">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full"
              icon="fa-money-bill"
              type="number"
              label="Precio (sin comillas)"
              color="primary"
              v-model="price"
              v-validate="'required'"
              name="Precio_Parqueo"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full">
          <div class="w-full mt-2 px-2">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full"
              icon="fa-vector-square"
              type="number"
              label="M2"
              color="primary"
              v-model="squareMeters"
              v-validate="'required'"
              name="Metros_Cuadreados_Bodega"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full">
          <div class="w-full mt-2 px-2">
            <span>Seleccione Estado</span>
            <v-select
              v-if="activeModifyStatus"
              v-model="status"
              :options="propertiesStatus"
            ></v-select>
            <vs-input
              v-else
              size="large"
              class="w-full"
              color="primary"
              :value="status"
              v-validate="'required'"
              disabled
            />
          </div>
        </vs-col>
      </vs-row>
      <br />
      <vs-row>
        <vs-col class="sm:w-full"></vs-col>
        <vs-col class="sm:w-full">
          <vs-button
            color="success"
            icon-pack="feather"
            icon="icon-save"
            type="filled"
            ref="loadableButton"
            id="button-with-loading"
            class="vs-con-loading__container w-full"
            :disabled="!fieldsReady"
            @click="updateWarehouse()"
            >Guardar
            {{ $tc("message.warehouse", 1, user.countryCode) }}</vs-button
          >
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import vSelect from "vue-select";
import api from "../services/property.api";

export default {
  name: "FormularioBodega",
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      unitNumber: "",
      price: 0,
      status: "",
      squareMeters: 0,
      propertiesStatus: [],
      towerNumber: "",
      level: 0,
      activeModifyStatus: true,
    };
  },
  async mounted() {
    const propertiesStatus = await api.getPropertyStatus();

    this.propertiesStatus = propertiesStatus.filter(
      (status) => status.id === "AVAILABLE" || status.id === "BLOCKED"
    );

    this.propertiesStatus = this.propertiesStatus.map((obj) => {
      return { label: obj.name, value: obj.id };
    });
  },
  watch: {
    "$store.state.property.selectedPropertyId": async function () {
      const propertyId = this.$store.getters["property/selectedPropertyId"];
      this.property = await api.get(propertyId);
      this.fillPropertyParams();
    },
  },
  computed: {
    ...mapState("auth", ["user"]),
    fieldsReady() {
      if (
        !this.status ||
        !this.unitNumber ||
        !this.price ||
        !this.squareMeters ||
        !this.level
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    fillPropertyParams() {
      this.status = this.setStatus(this.property.status);
      if (
        this.property.status !== "AVAILABLE" &&
        this.property.status !== "BLOCKED"
      ) {
        this.activeModifyStatus = false;
      } else this.activeModifyStatus = true;

      this.price = this.property.price;
      this.unitNumber = this.property.unit_number;
      this.squareMeters = this.property.total_area;
      this.level = this.property.level;

      this.towerNumber = this.propertyAttribute("TOWER_NUMBER") || "";
    },
    async updateWarehouse() {
      const propertyId = this.$store.getters["property/selectedPropertyId"];
      try {
        await api.updateProperty(propertyId, {
          unitNumber: this.unitNumber,
          price: String(parseFloat(this.price)),
          status: this.activeModifyStatus
            ? this.status.value
            : this.property.status,
          totalArea: parseFloat(this.squareMeters),
          level: String(parseInt(this.level, 10)),
          tower: this.towerNumber,
        });

        this.$vs.notify({
          time: 6000,
          title: `${this.unitNumber}! 🏢🚀`,
          text: `Fue actualizado correctamente.`,
          color: "success",
        });
        this.$emit("closePopUp");
      } catch (e) {
        this.$vs.notify({
          title: `No ha sido posible crear la propiedad`,
          text: `No ha sido posible crear la propiedad`,
          color: "danger",
        });
      }
    },
    setStatus(status) {
      if (status == "AVAILABLE") return "Disponible";
      if (status == "RESERVED") return "Reservado";
      if (status == "ASSIGNED") return "Asignado";
    },
    propertyAttribute(attributeValue) {
      const attribute = this.property.property_attribute_values.find(
        (propertyValue) => propertyValue.property_attribute == attributeValue
      );

      if (!attribute) return null;

      return attribute.value;
    },
  },
};
</script>

<style lang="scss" scoped></style>