<template>
  <vs-card>
    <div slot="header">
      <h5>{{ properties.length }} registros seleccionados.</h5>
    </div>
    <div class="flex">
      <label for="" class="vs-input--label">Actualizar precio: &nbsp;</label>
      <vs-checkbox v-model="amount">por monto</vs-checkbox>
      <vs-checkbox v-model="percent">por porcentaje</vs-checkbox>
    </div>

    <div class="mt-5 flex">
      <label for="" class="vs-input--label">El precio debe: &nbsp;</label>
      <vs-checkbox v-model="add">aumentar</vs-checkbox>
      <vs-checkbox v-model="out">disminuir</vs-checkbox>
    </div>

    <div class="w-full sm:w-full mt-5" v-if="amount">
      <div>
        <vs-input
          size="large"
          icon-pack="fas"
          class="w-full px-2"
          icon="fa-money-bill"
          color="primary"
          type="number"
          min="0"
          v-model.number="amountValue"
          v-validate="'required'"
          name="Precio"
        />
      </div>
      <vs-button
        color="success"
        type="filled"
        icon="save"
        :disabled="amountValue <= 0 ? true : false"
        class="mt-5"
        @click="updateAllByAmount"
        >Actualizar registros</vs-button
      >
    </div>
    <div class="w-full sm:w-full mt-5" v-if="percent">
      <div class="flex">
        <vs-button
          class="mt-3"
          color="primary"
          type="filled"
          icon-pack="feather"
          icon="icon-minus"
          @click="percentSubtract"
        ></vs-button>
        <vs-input
          size="large"
          class="w-full px-2"
          color="primary"
          name="Precio"
          icon-pack="fas"
          icon="fa-percent"
          style="color: black"
          type="number"
          min="0"
          max="100"
          v-model.number="percentValue"
        />
        <vs-button
          class="mt-3"
          color="primary"
          type="filled"
          icon-pack="feather"
          icon="icon-plus"
          @click="percentAdd"
        ></vs-button>
      </div>
      <vs-button
        color="success"
        type="filled"
        icon="save"
        :disabled="percentValue ? false : true"
        class="mt-5"
        @click="updateAllByPercent"
        >Actualizar registros</vs-button
      >
    </div>
  </vs-card>
</template>

<script>
import { propertyApi } from "../services";
import { sentryCaptureException } from "../../../helpers/Sentry";

export default {
  props: ["properties"],
  watch: {
    amount(val) {
      this.percent = !val;
      this.percentValue = 0;
    },
    amountValue(val) {
      if (val < 0) this.amountValue = 0;
    },
    percent(val) {
      this.amount = !val;
      this.amountValue = 0;
    },
    percentValue(val) {
      if (val > 100) this.percentValue = 100;
      if (val < 0) this.percentValue = 0;
    },
    add(val) {
      this.out = !val;
    },
    out(val) {
      this.add = !val;
    },
  },
  data() {
    return {
      amount: true,
      percent: false,
      amountValue: 0,
      percentValue: 0.0,
      add: true,
      out: false,
    };
  },
  methods: {
    async updateAllByAmount() {
      let batchProperties = [];
      try {
        batchProperties = this.properties.map((item) =>
          propertyApi.updateProperty(item.id, {
            price: (
              item.price +
              parseFloat(this.add ? this.amountValue : this.amountValue * -1)
            ).toString(),
          })
        );

        await Promise.all(batchProperties);

        this.$vs.notify({
          time: 6000,
          title: `Actualización correcta 🏢🚀`,
          text: `Se han actualizado ${batchProperties.length} registros.`,
          color: "success",
        });

        this.amountValue = 0;
        this.add = true;
        this.out = false;
        this.$emit("closePopup");
      } catch (e) {
        this.$vs.notify({
          title: `Error`,
          text: `No ha sido posible actualizar la propiedad`,
          color: "danger",
        });
      }
    },
    percentSubtract() {
      this.percentValue--;
      if (this.percentValue <= 0) {
        this.percentValue = 0;
      }
    },
    percentAdd() {
      this.percentValue++;
      if (this.percentValue >= 100) {
        this.percentValue = 100;
      }
    },
    async updateAllByPercent() {
      let batchProperties = [];
      try {
        const percentAmount = this.percentValue / 100;
        if (this.add) {
          batchProperties = this.properties.map((item) =>
            propertyApi.updateProperty(item.id, {
              price: (item.price + item.price * percentAmount).toString(),
            })
          );
        } else {
          batchProperties = this.properties.map((item) =>
            propertyApi.updateProperty(item.id, {
              price: (item.price - item.price * percentAmount).toString(),
            })
          );
        }

        await Promise.all(batchProperties);
        this.$vs.notify({
          time: 6000,
          title: `Actualización correcta 🏢🚀`,
          text: `Se han actualizado ${batchProperties.length} registros.`,
          color: "success",
        });
        this.percentValue = 0;
        this.add = true;
        this.out = false;
        this.$emit("closePopup");
      } catch (e) {
        sentryCaptureException(e, "properties");
        this.$vs.notify({
          title: `Error`,
          text: `No ha sido posible actualizar la propiedad`,
          color: "danger",
        });
      }
    },
  },
};
</script>

<style></style>
