<template>
  <vx-card>
    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
      <h4 class="mt-4 mb-4">Unidad: {{ propertyAssigned.unit_number }}</h4>
    </vs-col>
    <br />
    <div v-if="!property || (property && property.status !== 'RESERVED')">
      <vs-row>
        <vs-col class="sm:w-full lg:w-full p-1">
          <div class="w-full sm:w-full mt-2">
            <p class="vs-input--label">Número de propiedad a asignar:</p>
            <v-select
              class="px-2"
              v-model="property"
              :options="properties"
              label="unit_number"
              @search="fetchProperty"
            ></v-select>
          </div>
        </vs-col>
      </vs-row>
      <br />
      <vs-row>
        <vs-col class="sm:w-full lg:w-full p-1">
          <vs-button
            color="success"
            :disabled="!propertyIsSelected"
            icon-pack="feather"
            icon="icon-save"
            type="filled"
            ref="loadableButton"
            id="button-with-loading"
            class="vs-con-loading__container w-full"
            @click="assignProperties"
            >{{ propertyNameSelected }}</vs-button
          >
        </vs-col>
      </vs-row>
    </div>
    <div v-else class="mt-4">
      <p>
        <span class="error">Error: No es posible completar la acción.</span>
        <br />
        La unidad <b>{{ propertyAssigned.unit_number }}</b> ya se encuentra asignada a la 
        propiedad <b>{{ property.unit_number }}</b>. <br><br>
        
        Verifica la siguiente información:
        <table class="error-table">
            <thead>
                <tr>
                    <th>Unidad</th>
                    <th>Estado</th>
                </tr>
            </thead>
            <tr>
                <td>{{property.unit_number}}</td>
                <td>{{property.property_status.name }}</td>
            </tr>
            <tr>
                <td>{{propertyAssigned.unit_number}}</td>
                <td>{{propertyAssigned.property_status.name }}</td>
            </tr>
        </table>
      </p>
    </div>
  </vx-card>
</template>
<script>
import vSelect from "vue-select";
import { propertyApi, assignedPropertyApi } from "../services";

export default {
  props: ["propertyAssigned"],
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      properties: [],
      property: null,
      oldProperty: null,
      updateField: false,
    };
  },
  computed: {
    propertyIsSelected() {
      if (!this.property) return false;
      return true;
    },
    propertyNameSelected() {
      if (!this.property) return "...";
      return `Asignar a propiedad ${this.property.unit_number}`;
    },
  },
  watch: {
    propertyAssigned(newValue) {
      this.getMainPropertyAssigned(newValue.id);
    },
  },
  methods: {
    async getMainPropertyAssigned(assignedPropertyId) {
      await this.fetchProperty();
      const assigned = await assignedPropertyApi.getPropertiesByAssigned(
        assignedPropertyId
      );

      if (!assigned.length) {
        this.property = null;
        this.oldProperty = null;
        this.updateField = false;

        return;
      }

      this.property = assigned[0].propertyByPropertyId;
      this.oldProperty = assigned[0].propertyByPropertyId;
      this.updateField = true;
    },
    async fetchProperty(search) {
      if (!search) {
        search = "";
      }
      const notProperty = [this.propertyAssigned.property_type.id];
      const projectId = this.$store.state.project.currentProjectId;
      this.properties = await propertyApi.searchProperty(
        projectId,
        notProperty,
        search
      );
    },
    async assignProperties() {
      try {
        if (!this.updateField) {
          await propertyApi.allocateProperty(this.property.id, this.propertyAssigned.id);

          this.$vs.notify({
              time: 6000,
              title: `La propiedad ${this.property.unit_number} fue asignada a ${this.propertyAssigned.unit_number}! 🏢🚀`,
              color: "success"
          });
          this.$root.$emit('close-popup');
        } else {
          if(this.property.id !== this.oldProperty.id){
            await propertyApi.deallocateProperty(this.oldProperty.id, this.propertyAssigned.id);
            await propertyApi.allocateProperty(this.property.id, this.propertyAssigned.id);
          }

          this.$vs.notify({
              time: 6000,
              title: `La propiedad ${this.property.unit_number} fue asignada a ${this.propertyAssigned.unit_number}! 🏢🚀`,
              text: `Fue asignado correctamente.`,
              color: "success"
          });
          this.$root.$emit('close-popup');
        }
      } catch (e) {
        this.$vs.notify({
          title: `No ha sido posible asignar la propiedad`,
          text: `No ha sido posible asignar la propiedad`,
          color: "danger",
        });
      }
    },
  },
};
</script>

<style>
.fill-row {
  flex: 0 0 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

#div-with-loading {
  width: 200px;
  height: 200px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
}

.error {
  color: red;
}

.error-table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
}
.error-table thead th {
    text-align: center;
}
.error-table, .error-table th, .error-table td {
  border: 1px solid;
  padding: 2px;
}

</style>
